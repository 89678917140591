import React, { useState } from 'react';

const Switcher = ({ id, name, onChange, checked, disabled }) => {
  const onSwitcherChange = (event) => {
    if (onChange !== undefined && typeof onChange === 'function' && !disabled) {
      onChange(event);
    }
  };

  return (
    <>
      <div className="switcher">
        <label className="switch">
          <input
            id={id}
            name={name}
            type="checkbox"
            onChange={onSwitcherChange}
            checked={checked}
            disabled={disabled}
          />
          <span className="slider round">{checked ? '使用' : '取消'}</span>
        </label>
      </div>

      <style jsx>
        {`
          .switcher {
            display: flex;
            justify-content: center;
            align-items: center;
            .switch {
              position: relative;
              display: inline-block;
              width: 60px;
              height: 24px;

              input {
                opacity: 0;
                width: 0;
                height: 0;
              }
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.4s;
              transition: 0.4s;

              // display: flex;
              // font-size: 10px;
              // align-items: center;
              // place-content: end;
              // color: darkgrey;
              // padding-right: 8px;

              color: darkgrey;
              font-size: 14px;
              display: flex;
              align-items: center;
              flex-flow: row-reverse;
              padding-right: 6px;

              &:before {
                position: absolute;
                content: '';
                height: 22px;
                width: 22px;
                left: 1px;
                bottom: 1px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }

              &.round {
                border-radius: 34px;

                &:before {
                  border-radius: 50%;
                }
              }
            }

            input:checked + .slider {
              background-color: #5fd2da;
              place-content: start;

              // color: white;
              // padding-left: 8px;

              color: white;
              flex-flow: row;
              padding-left: 6px;
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #5fd2da;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(36px);
              -ms-transform: translateX(36px);
              transform: translateX(36px);
            }
          }
        `}
      </style>
    </>
  );
};

export default Switcher;
