import React from 'react';

const Radio = ({ id, name, value, label, onChange, defaultValue }) => {
  return (
    <>
      <div>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          onChange={(event) =>
            onChange !== undefined && typeof onChange === 'function'
              ? onChange(event)
              : undefined
          }
          checked={defaultValue.toString() === value}
        />
        {/* {defaultValue !== undefined && defaultValue.toString() === value ? (
          <input
            type="radio"
            checked
            id={id}
            name={name}
            value={value}
            onChange={(event) =>
              onChange !== undefined && typeof onChange === 'function'
                ? onChange(event)
                : undefined
            }
          />
        ) : (
          <input
            type="radio"
            id={id}
            name={name}
            value={value}
            onChange={(event) =>
              onChange !== undefined && typeof onChange === 'function'
                ? onChange(event)
                : undefined
            }
          />
        )} */}

        <label htmlFor={id}>
          <span>
            <span></span>
          </span>
          <div>{label}</div>
        </label>
      </div>

      <style jsx>
        {`
          input[type='radio'] {
            display: none;
          }
          input[type='radio'] + label {
            color: #333333;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            div {
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              margin-left: 7px;
            }
          }

          input[type='radio'] + label span {
            width: 14px;
            height: 14px;
            background-color: #d2d2d2;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            span {
              width: 10px;
              height: 10px;
              background-color: #ffffff;
              order-radius: 50%;
            }
          }

          input[type='radio']:checked + label span {
            width: 14px;
            height: 14px;
            background-color: #5fd2da;
            display: flex;
            justify-content: center;
            align-items: center;
            order-radius: 50%;

            span {
              width: 4px;
              height: 4px;
              background-color: #ffffff;
              order-radius: 50%;
            }
          }
        `}
      </style>
    </>
  );
};

export default Radio;
